/**
 * Configuration Tool
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

/* eslint-disable n/no-unsupported-features/node-builtins */

const tool = document.querySelector('#konfigurator');

const initializeConfigurationTool = (scroll = false) => {
	tool.innerHTML =
		'<bathroom-configurator key="b7679669-bf96-4119-87e0-35db35024849" height="860"></bathroom-configurator>';
	const script = document.createElement('script');
	script.src =
		'https://www.badundheizung.de/konfigurator/public/configurators/bathroom-configurator/bathroom-configurator.js';
	script.async = true;
	tool.append(script);
	if (scroll) {
		tool.scrollIntoView();
	}
};

const renderConsent = (container, callback) => {
	container.innerHTML = `
		<div class="consent-request">
			<div class="consent-toggle">
				<button type="button" class="button button-secondary">Badkonfigurator anzeigen</button>
			</div>
			<div class="consent-disclaimer">
				<p>Wir verwenden einen Badkonfigurator von bad&amp;heizung. Mit Klick auf den Button stimmen Sie zu, dass von bad&amp;heizung Daten über die Nutzung des Badkonfigurators erhoben, verarbeitet und genutzt werden.</p>
				<p>Nähere Informationen über die Daten&shy;verarbeitung durch bad&amp;heizung können Sie der <a href="https://www.badundheizung.de/datenschutz/">Datenschutzerklärung von bad&amp;heizung</a> entnehmen.</p>
			</div>
		</div>
	`;

	const toggle = document.querySelector('.consent-toggle button');
	if (toggle) {
		toggle.addEventListener('click', () => {
			callback();
		});
	}
};

if (tool) {
	if (localStorage.getItem('allow-configuration-tool') === 'true') {
		initializeConfigurationTool();
	} else {
		renderConsent(tool, () => {
			localStorage.setItem('allow-configuration-tool', 'true');
			initializeConfigurationTool(true);
		});
	}
}
