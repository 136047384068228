/**
 * Navigation
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

const activeClass = 'show-navigation';
const content = document.querySelector('main');
const navigation = document.querySelector('.main-nav');
const links = navigation?.querySelectorAll('a') ?? [];

const toggleNavigation = () => {
	document.documentElement.classList.toggle(activeClass);
	const toggleButton = document.querySelector('#toggle-navigation');
	if (toggleButton) {
		toggleButton.setAttribute(
			'aria-pressed',
			toggleButton.getAttribute('aria-pressed') === 'false'
				? 'true'
				: 'false',
		);
	}
};

if (content) {
	// Handle close by click on content
	content.addEventListener('click', () => {
		if (document.documentElement.classList.contains(activeClass)) {
			toggleNavigation();
		}
	});

	// Handle close by Escape key
	document.addEventListener('keyup', (event) => {
		if (
			document.documentElement.classList.contains(activeClass) &&
			event.key === 'Escape'
		) {
			toggleNavigation();
		}
	});
}

// Add toggle button to DOM if there is a navigation
if (navigation) {
	const toggleButton = document.createElement('BUTTON');
	toggleButton.type = 'button';
	toggleButton.id = 'toggle-navigation';
	toggleButton.setAttribute('aria-label', 'Navigation anzeigen');
	toggleButton.setAttribute('aria-controls', 'navigation');
	toggleButton.setAttribute('aria-pressed', 'false');
	toggleButton.innerHTML = '<span></span><span></span><span></span>';
	toggleButton.addEventListener('click', toggleNavigation);
	navigation.prepend(toggleButton);
}

// Prevent clicking the current menu link
for (const link of links) {
	if (link && link.href === location.href) {
		link.addEventListener('click', (event) => {
			event.preventDefault();
			link.blur();

			if (link.hash) {
				const target = document.querySelector(link.hash);
				if (target) {
					target.scrollIntoView({behavior: 'smooth'});
				}
			} else {
				window.scrollTo(0, 0);
			}

			if (document.documentElement.classList.contains(activeClass)) {
				toggleNavigation();
			}
		});
	}
}
