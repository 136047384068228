/**
 * Consent Manager
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

/* eslint-disable n/no-unsupported-features/node-builtins */

const consent = document.querySelectorAll('.consent-remove');

for (const element of consent) {
	const id = `allow-${element.dataset.consent}`;
	if (localStorage.getItem(id) === 'true') {
		const paragraph = document.createElement('p');
		const button = document.createElement('button');
		button.type = 'button';
		button.classList.add('button', 'button-secondary');
		button.textContent = 'Zustimmung zurücknehmen';
		button.addEventListener('click', () => {
			localStorage.removeItem(id);
			button.remove();
			paragraph.textContent = 'Zustimmung zurückgenommen.';
			paragraph.role = 'alert';
			paragraph.classList.add('message', 'message-success');
		});
		paragraph.append(button);
		element.append(paragraph);
	}
}
